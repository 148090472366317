import React, { useState, useEffect, useCallback }  from 'react'
import { useNavigate  } from 'react-router-dom'
import { questions } from '../Data/QuestionData'
import Question from '../../components/Question/Question'
import Cookies from 'js-cookie'
import ActionButton from '../../components/ActionButton/ActionButton'
import IntroInfo from '../../components/IntroInfo/IntroInfo'
import styles from './Questions.module.scss'

const Questions = () => {
    const [currQuestionIndex, setCurrQuestionIndex] = useState(0)
    const [currQuestion, setCurrQuestion] = useState(questions[0].question)
    const [yesBtnVisible, setYesBtnVisible] = useState(true)
    const [noBtnVisible, setNoBtnVisible] = useState(true)
    const [isAnimating, setIsAnimating] = useState(false)
    const [answers, setAnswers] = useState([])
    const [yesSelected, setYesSelected] = useState(false)
    const [noSelected, setNoSelected] = useState(false)
    const [completed, setCompleted] = useState(false)
    const [atLeastOneYes, setAtLeastOneYes] = useState(false)
    const [bgClass, setBgClass] = useState(questions[0].bgClass)
    
    // Listen for when we've answered all the questions
    const finishedQuestions = useCallback(() => {
        if (answers.length === questions.length ) {
            Cookies.set('tinyHabits', JSON.stringify(answers))
        }
    }, [answers])

    useEffect(() => {
        setCurrQuestion(questions[currQuestionIndex]?.question)
        setBgClass(questions[currQuestionIndex]?.bgClass)
    }, [currQuestionIndex])

    useEffect(() => {
        finishedQuestions()
    }, [finishedQuestions])

    const reflowQuestion = () => {
        var el = document.getElementById('question-box');
        el.style.animation = 'none';
        // eslint-disable-next-line no-unused-expressions
        el.offsetHeight; /* trigger reflow */
        el.style.animation = null;
    }

    const nextQuestion = (e) => {
        const answer = e.target.innerHTML
        if (answer === 'Yes') {
            setYesSelected(true)
            setAtLeastOneYes(true)
        }
        if (answer === 'No') setNoSelected(true)
        setAnswers([...answers, answer])

        setIsAnimating(true)
        setTimeout(() => {
            setIsAnimating(false)
            let nextQuestionIndex = currQuestionIndex + 1 
            if (nextQuestionIndex > 0) {
                setCurrQuestionIndex(nextQuestionIndex)
                setYesBtnVisible(true) 
                setNoBtnVisible(true)
            }
            // Last question
            if (nextQuestionIndex === questions.length -1 ) {
                setCurrQuestionIndex(nextQuestionIndex)
            }
            if (nextQuestionIndex === questions.length ) {
                setYesBtnVisible(false) 
                setNoBtnVisible(false)
                setCompleted(true)
            }
            setYesSelected(false)
            setNoSelected(false)
            reflowQuestion()
        }, 1000)                
    }

    const YesButton = () => {
        return <ActionButton label="Yes" onClick={nextQuestion} secondary={true} selected={yesSelected} disabled={isAnimating} />
    }

    const NoButton = () => {
        return <ActionButton label="No" onClick={nextQuestion} secondary={true} selected={noSelected} disabled={isAnimating} />
    }

    const competedContent = `
        <h2>Question section completed</h2>
        <p>
            Now take a look at your tailored tiny habits advice
        </p>
    `

    const competedContentFail = `
        <h2>Question section completed</h2>
        <p>
            It doesn't look like there's any areas for you to make tiny habits, let's try again!
        </p>
    `

    const navigate = useNavigate()
    const nextPage = () => {
        navigate('/advice');
    }

    const backToStart = () => {
        navigate('/');
    }

    return(
        <>
            { !completed ? 
            <div className={`bg-water ${styles.main_container}`}>
                <div className={`bg-pipes-middle`}>
                    <div className={`bg-pipes ${styles.question_container}`}>
                        <div className={styles.content_container}>
                            <div className={styles.question_title}>
                                <h2><span className='txt-blue-med'>Question</span> {currQuestionIndex + 1 <= questions.length ? currQuestionIndex + 1 : questions.length}/{questions.length}</h2>
                            </div>
                            <div id="question-box" className={`bg-question-image ${bgClass} ${styles.intro_desc}`}>
                                <div className={styles.question_label}>
                                    <Question animate={isAnimating} label={currQuestion} />
                                </div>
                                <div className={styles.button_container}>
                                    { noBtnVisible ? <NoButton /> : null }
                                    { yesBtnVisible ? <YesButton /> : null }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={`bg-pipes-bottom`}></div>
            </div>
            : null }

            { completed && atLeastOneYes && 
            <IntroInfo 
                title="Thank You!" 
                content={competedContent}
                buttonLabel="Learn More"
                onClick={nextPage}
            /> }

            { completed && !atLeastOneYes && 
            <IntroInfo 
                title="Thank You!" 
                content={competedContentFail}
                buttonLabel="Start Over"
                onClick={backToStart}
            /> }
            
        </>
    )
}

export default Questions
