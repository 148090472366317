import React, { useState, useEffect, useCallback }  from 'react'
import { useNavigate  } from 'react-router-dom'
import Cookies from 'js-cookie'
import { advice } from '../Data/AdviceData'
import YouToldUs from '../../components/YouToldUs/YouToldUs'
import CouldYou from '../../components/CouldYou/CouldYou'
import ActionButton from '../../components/ActionButton/ActionButton'
import IntroInfo from '../../components/IntroInfo/IntroInfo'
import styles from './Advice.module.scss'

const Advice = () => {
    const navigate = useNavigate()

    const STEP_TYPE = {
        youToldUs: 'youToldUs',
        couldYou: 'couldYou',
    }
    const animDuration = 3000

    const [currIndex, setCurrIndex] = useState(0)
    const [stepType, setStepType] = useState(STEP_TYPE.youToldUs)
    const [stepAnswer, setStepAnswer] = useState(false)
    const [isAnimating, setIsAnimating] = useState(false)
    const [answers, setAnswers] = useState([])
    const [completed, setCompleted] = useState(false)

    // Get saved answers from cookie
    const myAnswers = JSON.parse(Cookies.get('tinyHabits'))
    const filteredAdvice = advice.filter((item, index) => myAnswers[index] === 'Yes')
    
    // Listen for when we've answered all the questions
    const finishedQuestions = useCallback(() => {
        if (answers.length === filteredAdvice.length ) {
            Cookies.set('tinyHabits', JSON.stringify(answers))
        }
    }, [answers, filteredAdvice])

    useEffect(() => {
        if (! myAnswers) navigate('/')
    }, [myAnswers, navigate])

    useEffect(() => {
        finishedQuestions()
    }, [finishedQuestions])

    const nextStep = () => {
        // Process and move onto next advice
        if (stepType === STEP_TYPE.couldYou) {
            setAnswers([...answers, stepAnswer])
            setIsAnimating(true)
            setTimeout(() => {
                setIsAnimating(false)
                let nextAdviceIndex = currIndex + 1 
                if (nextAdviceIndex > 0) {
                    setCurrIndex(nextAdviceIndex)
                    // Move to next step
                    setStepAnswer(false)
                    setStepType(STEP_TYPE.youToldUs)
                    setIsAnimating(true)
                    setTimeout(() => {
                        setIsAnimating(false)
                    }, animDuration)
                }
                // Last question
                if (nextAdviceIndex === filteredAdvice.length -1 ) {
                    setCurrIndex(nextAdviceIndex)
                    // Move to next step
                    setStepAnswer(false)
                    setStepType(STEP_TYPE.youToldUs)
                    setIsAnimating(true)
                    setTimeout(() => {
                        setIsAnimating(false)
                    }, animDuration)
                }
                if (nextAdviceIndex === filteredAdvice.length ) {
                    setCompleted(true)
                }
            }, 10)
        } else {
            setIsAnimating(true)
            setTimeout(() => {
                setIsAnimating(false)
                setStepType(STEP_TYPE.couldYou)
                // No tips buttons?
                if (filteredAdvice[currIndex]?.noBtns) {
                    setStepAnswer('N/A')
                }
            }, 10)
        }           
    }

    const optionSelected = (option) => {
        setStepAnswer(option)
    }

    const competedContent = `
        <h2>Thank you for using our Tiny Habits Tool</h2>
        <p>
            Next are the tiny habits you have decided to take on.
        </p>
        <p>
            Can you persuade anyone else to change their habits to support our wastewater network?
        </p>
    `

    const nextPage = () => {
        navigate('/results')
    }
 
    return(
        <>
        { !completed ? 
            <div className={`bg-water ${styles.main_container}`}>
                <div className={`bg-pipes-middle`}>
                    <div className={`bg-pipes ${styles.advice_container}`}>
                        <div className={styles.content_container}>
                            <div className={styles.advice_title}>
                                <h2><span className='txt-blue-med'>Advice</span> {currIndex + 1 <= filteredAdvice.length ? currIndex + 1 : filteredAdvice.length}/{filteredAdvice.length}</h2>
                            </div>
                            <div className={styles.feedback}>
                                { !completed && (stepType === STEP_TYPE.youToldUs) ? <YouToldUs filteredAdvice={filteredAdvice} currIndex={currIndex} /> : null }
                                { !completed && (stepType === STEP_TYPE.couldYou) ? <CouldYou filteredAdvice={filteredAdvice} currIndex={currIndex} optionSelected={optionSelected} /> : null }

                                <div className={styles.footer_button_container}>
                                    <ActionButton label="Next" onClick={nextStep} disabled={isAnimating || (stepType === STEP_TYPE.couldYou && !stepAnswer)} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={`bg-pipes-bottom`}></div>
            </div>
        : null }

        { completed && 
            <IntroInfo 
                title="Your Tiny Habits" 
                content={competedContent}
                buttonLabel="Get my Tiny Habits"
                onClick={nextPage}
            /> }
    </>
    )
}

export default Advice
