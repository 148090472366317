import React, { useEffect }  from 'react'
import { useNavigate  } from 'react-router-dom'
import Cookies from 'js-cookie'
import { habits } from '../Data/HabitsData'
import ActionButton from '../../components/ActionButton/ActionButton'
import styles from './Results.module.scss'

const Results = () => {
    const navigate = useNavigate()
    
    // Get saved answers from cookie
    const myAnswers = JSON.parse(Cookies.get('tinyHabits'))
    const hasHabits = myAnswers.includes('Yes')
    
    useEffect(() => {
        if (!myAnswers) navigate('/')
    }, [myAnswers, navigate])

    const nextPage = () => {
        if(hasHabits) {
            navigate('/poster')
        }
        else {
            navigate('/')
        }
    }
 
    return(
        <div className={`bg-water ${styles.main_container}`}>
            <div className={`bg-pipes-middle`}>
                <div className={`bg-pipes ${styles.results_container}`}>
                    <div className={styles.content_container}>
                        <div className={styles.advice_title}>
                            <h2><span className='txt-blue-med'>Your selected Tiny Habits</span></h2>
                        </div>
                        
                        { hasHabits ?
                            <div className={styles.results}>
                                <p>These are the tiny habits I have decided to take on to try to make small changes that can have a big impact on our wastewater network.</p>
                                { habits.map((item, index) => {
                                    return item !== false ?
                                        <div className={`${styles.result_box} ${myAnswers[index] === 'Yes' ? styles.selected : ''}`} key={`result-${index}`}>
                                            {item}
                                        </div>
                                    : null
                                })}
                            </div>
                        : null }

                        { !hasHabits ?
                            <div className={styles.results}>
                                <p>You've decided not to make any tiny habits. Let's try again.</p>
                            </div>
                        : null }

                        <div className={styles.footer_button_container}>
                            <ActionButton label="Next" onClick={nextPage} />
                        </div>
                    </div>
                </div>
            </div>
            <div className={`bg-pipes-bottom`}></div>
        </div>
    )
}

export default Results
