import React, { useState, useEffect } from 'react'
import styles from './ActionButton.module.scss'

const ActionButton = (props) => {
    const [btnLabel, setBtnLabel] = useState(props.label)
    const [selected, setSelected] = useState(props.selected)
    const [disabled, setDisabled] = useState(props.disabled)

    useEffect(() => {
        setBtnLabel(props.label)
        setSelected(props.selected)
        setDisabled(props.disabled)
    }, [props]);

    return(
        <button 
            className={`${styles.action_button} ${props.secondary ? styles.secondary : ''} ${selected ? styles.selected : ''} ${disabled ? styles.disabled : ''}`} 
            onClick={props.onClick} 
            disabled={disabled}
        >
            {btnLabel}
        </button>
    )
}

export default React.memo(ActionButton)
