export const questions = [
    {
        question: 'This is an easy one to get started - do you use a bathroom?',
        bgClass: 'bg-bathroom',
    },
    {
        question: 'Would you like to know some facts about fatbergs?',
        bgClass: 'bg-fatberg',
    },
    {
        question: 'Do you do washing up in the sink?',
        bgClass: 'bg-sink',
    },
    {
        question: 'Do you have take-aways at home?',
        bgClass: 'bg-take-away',
    },
    {
        question: 'Does anyone at home use wet wipes to remove makeup?',
        bgClass: 'bg-wet-wipe',
    },
    {
        question: 'Do you drink milk?',
        bgClass: 'bg-milk',
    },
    {
        question: 'Does anyone at home shave?',
        bgClass: 'bg-shave',
    },
    {
        question: 'Does anyone at home have periods?',
        bgClass: 'bg-period',
    },
    {
        question: 'Do you have anyone under the age of three at home?',
        bgClass: 'bg-toddler',
    },
    {
        question: 'Does anyone in your house use dental floss?',
        bgClass: 'bg-floss',
    },
    {
        question: 'Do you enjoy home cooking?',
        bgClass: 'bg-cooking',
    },
    {
        question: 'Does anyone at home use cotton ear buds for make up or cleaning ears?',
        bgClass: 'bg-cotton-buds',
    },
    {
        question: 'Do you eat meat?',
        bgClass: 'bg-meat',
    },
]
