import React, { useState, useEffect } from 'react'
import styles from './BounceEllipses.module.scss'

const BounceEllipses = (props) => {
    const [label, setLabel] = useState(props.label)
    const [animating, setAnimating] = useState(false)

    const anmDuration = 0.6
    const anmRepeat = 3
    const animDelay = 0.2
    const numDots = 3
    const totalDuration = ((anmDuration * anmRepeat) + ((animDelay * anmRepeat) * (numDots - 1)))

    const startAnim = () => {
        setAnimating(false)
        setAnimating(true)
        setTimeout(() => {
            setAnimating(false)
        }, totalDuration * 1000)
    }

    useEffect(() => {
        setLabel(props.label)
        startAnim()
    // eslint-disable-next-line
    }, [props]);

    return(
        <h2 className={`${styles.bouncy} ${animating ? styles.animate : ''}`}>{label}{ [...Array(numDots)].map((i, index) => <span key={`dot-${index}`}>.</span>)}</h2>
    )
}

export default React.memo(BounceEllipses)
