import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import ScrollToTop from './components/ScrollToTop/ScrollToTop'
import Welcome from './pages/Welcome/Welcome'
import Questions from './pages/Questions/Questions'
import Advice from './pages/Advice/Advice'
import Results from './pages/Results/Results'
import Poster from './pages/Poster/Poster'
import './styles/App.scss'

function App() {
  return (
    <Router>
        <ScrollToTop />
        <Routes>
            <Route path='/' element={<Welcome />} />
            <Route path='/questions' element={<Questions />} />
            <Route path='/advice' element={<Advice />} />
            <Route path='/results' element={<Results />} />
            <Route path='/poster' element={<Poster />} />
        </Routes>
    </Router>
  )
}

export default App
