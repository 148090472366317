import React from 'react'
import ActionButton from '../ActionButton/ActionButton'
import FooterLogos from '../FooterLogos/FooterLogos'
import styles from './IntroInfo.module.scss'

function IntroInfo(props) {

    const createMarkup = () => {
        return {__html: props.content}
    }
      
    const Content = () => {
        return <div dangerouslySetInnerHTML={createMarkup()} />
    }
    
    return(
        <div className={`bg-water ${styles.container}`}>
            
            <div className={styles.content_container}>
                <h1>{props.title}</h1>
                <div className={styles.intro_desc}>
                    <Content />
                </div>
                <div className={styles.button}>
                    <ActionButton onClick={props.onClick} label={props.buttonLabel} />
                </div>
            </div>

            <FooterLogos />
        </div>
    )
    
}

export default IntroInfo
