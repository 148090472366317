import React, { useState, useEffect } from 'react'
import BounceEllipses from '../BounceEllipses/BounceEllipses'
import styles from './YouToldUs.module.scss'

const YouToldUs = (props) => {
    const [currAdvice, setCurrAdvice] = useState(props.filteredAdvice[props.currIndex])
    
    useEffect(() => {
        setCurrAdvice(props.filteredAdvice[props.currIndex])
    }, [props.filteredAdvice, props.currIndex])
   
    return(
        <div className={styles.container}>
            <div className={styles.advice_container}>
                <BounceEllipses label="You told us" index={props.currIndex} />
                <div className={styles.answer_box}>
                    <p>{ currAdvice.told }</p>
                </div>
            </div>
        </div>
    )

}

export default YouToldUs
