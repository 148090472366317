import React from 'react'
import { useNavigate  } from 'react-router-dom'
import Cookies from 'js-cookie'
import IntroInfo from '../../components/IntroInfo/IntroInfo'
import styles from './Welcome.module.scss'

function Welcome() {
    const navigate = useNavigate()
    const nextPage = () => {
        navigate('/questions');
    }

    Cookies.set('tinyHabits', false)

    const content = `
        <h2>Welcome to the Tiny Habits Tool</h2>
        <p>
            The wastewater network is struggling with the impact of unflushables, and at Northumbrian Water we are big fans of supporting you to make small changes that can have a big impact.
        </p>
        <p>
            Tell us a little about your life and we can share some top tiny habits you can adopt to help keep the water flowing - in the right direction!
        </p>
    `

    return(
        <div className={styles.welcome}>
            <IntroInfo 
                title="Tiny Habits" 
                content={content}
                buttonLabel="Get Started"
                onClick={nextPage}
            />
        </div>
    )
    
}

export default Welcome
