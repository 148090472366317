import React from 'react'
import northLogo from '../../images/northumbrian-logo.svg'
import rippleLogo from '../../images/ripple-effect-logo.svg'
import styles from './FooterLogos.module.scss'

function FooterLogos() {

    return(
        <div className={styles.footer}>
            <img className={styles.north_logo} src={northLogo} alt="Northumbrian Water" />
            <img className={styles.ripple_logo} src={rippleLogo} alt="Ripple Effect" />
        </div>
    )
    
}

export default FooterLogos
