import React, { useState, useEffect }  from 'react'
import { useNavigate } from 'react-router-dom'
import { PDFDownloadLink } from '@react-pdf/renderer';
import Cookies from 'js-cookie'
import { habits } from '../Data/HabitsData'
import ActionButton from '../../components/ActionButton/ActionButton'
import { MyPoster } from '../../components/MyPoster/MyPoster'
import posterPreview from '../../images/poster-preview.jpg'
import styles from './Poster.module.scss'

const Poster = () => {
    const navigate = useNavigate()

    // Get saved answers from cookie
    const myAnswers = JSON.parse(Cookies.get('tinyHabits'))
    const filteredHabits = habits.filter((item, index) => myAnswers[index] === 'Yes')
    
    useEffect(() => {
        if (! myAnswers) navigate('/')
    }, [myAnswers, navigate])

    const STEP_TYPE = {
        form: 'form',
        download: 'download',
    }

    const [stepType, setStepType] = useState(STEP_TYPE.form)
    const [name, setName] = useState('')
    const [weeks, setWeeks] = useState('')
    const [people, setPeople] = useState('')

    const nextStep = () => {
        setStepType(STEP_TYPE.download)
        window.scrollTo(0, 0)
    }

    const backToStart = () => {
        navigate('/');
    }

    const filterInput = (e) => {
        const value = e.target.value
        if (value > 0) return value
        return ''
    }

    const filterInputWeeks = (e) => {
        const value = filterInput(e)
        setWeeks(value)
    }

    const filterInputPeople = (e) => {
        const value = filterInput(e)
        setPeople(value)
    }

    return(
        <div className={`bg-water ${styles.main_container}`}>
            <div className={`bg-pipes-middle`}>
                <div className={`bg-pipes ${styles.results_container}`}>
                    <div className={styles.content_container}>
                        <div className={styles.advice_title}>
                            <h2><span className='txt-blue-med'>{stepType === STEP_TYPE.form ? 'Create your Tiny Habits poster' : `Thanks ${name}, for using the Tiny Habits tool` }</span></h2>
                        </div>
                        
                        { stepType === STEP_TYPE.form ? (
                            <>
                                <div className={styles.form}>
                                    <p>Enter your details to create and download your own poster</p>
                                    <div className={styles.inner}>
                                        <div className={styles.form_input_container}>
                                            <label>My name is:</label>
                                            <input type="text" value={name} maxLength="20" onChange={(e) => setName(e.target.value)} />
                                        </div>
                                        <div className={styles.input_half}>
                                            <div className={styles.form_input_container}>
                                                <label>I will give myself the following time to make my Tiny Habits permanent:</label>
                                                <div className={styles.suffix}>
                                                    <input type="text" value={weeks} onChange={(e) => filterInputWeeks(e)} />
                                                    <label>{weeks === '1' ? 'Week' : 'Weeks'}</label>
                                                </div>
                                            </div>
                                            <div className={styles.form_input_container}>
                                                <label>How many other people can you persuade to make the changes?</label>
                                                <div className={styles.suffix}>
                                                    <input type="text" value={people} onChange={(e) => filterInputPeople(e)} />
                                                    <label>{people === '1' ? 'Other person' : 'Other people'}</label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className={styles.footer_button_container}>
                                    <ActionButton label="Next" onClick={nextStep} disabled={!name || !weeks || !people} />
                                </div>
                            </>
                        ) : null }

                        { stepType === STEP_TYPE.download ? (
                            <div className={styles.download}>
                                <div className={styles.poster}>
                                    <img src={posterPreview} alt="My Tiny Habits poster preview" />
                                </div>
                                <div className={styles.details}>
                                    <div className={styles.text_container}>
                                        <p>You decided to make your Tiny Habits permanent in <span className="txt-blue-med">{weeks} {weeks === '1' ? 'week' : 'weeks'}</span>.</p>
                                        <p>Good luck with persuading <span className="txt-blue-med">{people} {people === '1' ? 'other' : 'others'}</span> to do the same!</p>
                                    </div>
                                    <div className={styles.button_container}>
                                        <PDFDownloadLink 
                                            className={styles.download_button}
                                            document={<MyPoster data={{name, weeks, people, habits: filteredHabits}}/>} 
                                            fileName="MyTinyHabits.pdf"
                                        >
                                            {({ blob, url, loading, error }) =>
                                                loading ? 'Creating...' : 'Download'
                                            }
                                        </PDFDownloadLink>
                                        <ActionButton label="Finish" onClick={backToStart} />
                                    </div>
                                </div>
                            </div>
                        ) : null }
                        
                    </div>
                </div>
            </div>
            <div className={`bg-pipes-bottom`}></div>
        </div>
    )
}

export default Poster
