export const advice = [
    {
        told: 'You use a bathroom',
        tip: 'Here’s a really simple one - don’t put anything down the toilet other than pee, poo or toilet paper! Our wastewater network isn’t built to break down any other items and unflushable items will often cause blockages.'
    },
    {
        told: 'You would like to know some facts about fatbergs',
        tip: 'Fatbergs are made up of fats, oils and grease, along with other unflushable items. The biggest fatberg to date in the UK weighed was discovered in a sewer at Birchall Street in Liverpool. It weighed 400 tonnes and was 250 metres long. Apparently fatbergs smell like rotting meat and a smelly toilet combined…you definitely don’t want one in your pipes.',
        noBtns: true,
    },
    {
        told: 'You do washing up in the sink',
        tip: 'Did you know you can use sink strainers to protect our wastewater network? This simple product will only let water pass through  - you can use them in the kitchen and in the bathroom - they’ll stop unwanted hair making its way into the sewer. Simply pop the contents of the strainers in the bin.',
    },
    {
        told: 'You have take-aways at home',
        tip: 'A great tiny habit to consider is always scraping your plates before giving them a wash. Remove as much food, grease and oil before washing them in the sink to help keep our wastewater network working.',
    },
    {
        told: 'Someone at home uses wet wipes',
        tip: 'Wet wipes are brilliantly convenient, particularly if there are little ones at home. Unfortunately wet wipes are also at the root of lots of sewer pipe blockages. They often contain plastic and aren’t designed to be flushed down the toilet. Even if they say they are flushable the best tiny habit you can develop is to put them in the bin. There are lots more reusable options when it comes to removing make-up, once you’ve used them once you can pop them in the wash so they are clean and ready to be used again!',
    },
    {
        told: 'You drink milk',
        tip: 'It might surprise you but you should try and keep milk out of your sink. If a bottle of milk goes off, the best place for it is in the bin. You can find a non recyclable container and pour the milk into it, before throwing it into a bin.',
    },
    {
        told: 'Someone at home shaves',
        tip: 'Razors should never be flushed down the toilet - they are often found in fatbergs and cause blockages. Your tiny habit could be encouraging people at home to always dispose of razors in the bin. Don’t forget to wrap the razor so the sharp edge doesn’t hurt anyone.',
    },
    {
        told: 'Someone at home has periods',
        tip: 'Period products are not designed to break down in our wastewater network, especially as they often contain plastics - tampons might look as if they will break down but they are designed to stay intact. Tampons and sanitary towels should always go in the bin. This change in habit could make a massive difference to our network. For a bigger, more sustainable change you could explore sustainable alternatives online to traditional tampons and towels.',
    },
    {
        told: 'You have someone under the age of three at home',
        tip: 'Wet wipes are brilliantly convenient, particularly if there are little ones at home. Unfortunately wet wipes are also at the root of lots of sewer pipe blockages. Some are often made of plastic and aren’t designed to be flushed down the toilet. Even if they say they are flushable the best tiny habit you can develop is to put them in the bin. There are lots more reusable options when it comes to removing make-up, once you’ve used them once you can pop them in the wash so they are clean and ready to be used again!',
    },
    {
        told: 'Someone in your house uses dental floss',
        tip: 'This is an easy one. Dental floss must always go in the bin. It isn’t biodegradable due to the mixture of materials used to make it. This is a great tiny habit to try and keep to! '
    },
    {
        told: 'You enjoy home cooking',
        tip: 'Did you know you can recycle your cooking oil? Wait until it has cooled and you can use a strainer to remove any food debris and then store it in a sealable container. It’s then ready to be used another time. Remember oil should never be poured down the sink. Your tiny habit could be using a “gunk pot”. A gunk pot is a container you can use to collect and hold oil and fat until it has cooled. You can also make your own out of newspaper.',
    },
    {
        told: 'Someone at home uses cotton ear buds',
        tip: 'Perhaps your tiny habit could be encouraging everyone at home to use a bathroom bin to ensure unflushables stay out of our wastewater network!',
    },
    {
        told: 'You eat meat',
        tip: 'During the cooking process, many types of meat release a substantial amount of fat which then gets rinsed down the sink. Your tiny habit could be to let the pan or tray cool and then scrape the congealed fat, oil or grease into a bin or gunk pot before washing up.',
    },
]
