import React from 'react'
import MovingComponent from 'react-moving-text'
import styles from './Question.module.scss'

const Question = (props) => {
   
    return(
        <MovingComponent
            type={ props.animate ? 'slideOutToLeft' : null }
            duration="1250ms"
            delay="0s"
            direction="normal"
            timing="ease"
            iteration="1"
            fillMode="none"
            >
                <div className={styles.question_label}>
                    {props.label}
                </div>
        </MovingComponent>
    )

}

export default Question
