import React from 'react';
import { Document, Page, Text, View, Image, StyleSheet, Font } from '@react-pdf/renderer';
import posterFull from '../../images/poster/poster-full.jpg'
import poster3 from '../../images/poster/poster_3.jpg'
import poster2 from '../../images/poster/poster_2.jpg'
import poster1 from '../../images/poster/poster_1.jpg'
import roboto from '../../fonts/RobotoSlab-Bold-webfont.woff'
import ibmPlex from '../../fonts/ibmplexsans-bold-webfont.woff'
import luckiestGuy from '../../fonts/luckiestguy-regular-webfont.woff'

// Fonts
Font.register({ family: 'Roboto', src: roboto });
Font.register({ family: 'ibmPlex', src: ibmPlex });
Font.register({ family: 'luckiestGuy', src: luckiestGuy });

const hyphenationCallback = (word) => [word]
Font.registerHyphenationCallback(hyphenationCallback);

// Styles
const pdfStyles = StyleSheet.create({
    page: {
        backgroundColor: 'white',
        flexDirection: 'row',
    },
    myName: {
        fontFamily: 'luckiestGuy',
        fontSize: '32px',
        color: '#1A4A91',
        position: 'absolute',
        top: '40px',
        left: '0px',
        right: '0px',
        marginHorizontal: 'auto',
        textAlign: "center",
        justifyContent: 'center',
    },
    text: {
        fontFamily: 'Roboto',
        fontSize: '16px',
        color: '#032835',
        position: 'absolute',
        left: '0px',
        right: '0px',
        marginHorizontal: 'auto',
        textAlign: "center",
        justifyContent: 'center',
    },
    habitContainer: {
        width: '195px',
        height: '110px',
        position: "absolute",
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        // border: '1px solid red',
    },
    habitText: {
        textAlign: 'center',
        fontFamily: 'ibmPlex',
        color: '#1A4A91',
        fontSize: '12px',
    }
})

const habitBoxPositions = {
    one: {
        tally_1: {
            top: '400px', left: '200px'
        },
        tally_2: {
            top: '400px', left: '75px'
        },
        tally_3: {
            top: '325px', left: '75px'
        },
        tally_4: {
            top: '325px', left: '75px'
        }
    },
    two: {
        tally_1: {
            top: '325px', right: '75px'
        },
        tally_2: {
            top: '400px', right: '75px'
        },
        tally_3: {
            top: '325px', right: '75px'
        },
        tally_4: {
            top: '325px', right: '75px'
        }
    },
    three: {
        tally_1: {
            top: '480px', left: '75px'
        },
        tally_2: {
            top: '480px', left: '75px'
        },
        tally_3: {
            top: '475px', left: '200px'
        },
        tally_4: {
            top: '480px', left: '75px'
        }
    },
    four: {
        tally_1: {
            top: '480px', right: '75px'
        },
        tally_2: {
            top: '480px', right: '75px'
        },
        tally_3: {
            top: '480px', right: '75px'
        },
        tally_4: {
            top: '480px', right: '75px'
        }
    }
}

// The poster normally has 4 slots
const numHabitFields = 4

const pageContent = (pageNum, pageNumItems, data) => {
    const habitOffset = pageNum * numHabitFields
    let posterImg = posterFull
    switch (pageNumItems) {
        case 1:
            posterImg = poster1
            break
        case 2: 
            posterImg = poster2
            break
        case 3: 
            posterImg = poster3
            break
        default:
            posterImg = posterFull
    }

    return (
        <Page size="A4" style={pdfStyles.page} key={`page-${pageNum}`}>
            <View>
                <Image src={posterImg}></Image>
            </View>
            <Text style={pdfStyles.myName} >
               {data.name}{data.name.endsWith('s') ? "'" : "'s"}
            </Text>
            <Text style={{top: '265px', ...pdfStyles.text }} >
                Within {data.weeks} {data.weeks === '1' ? 'week' : 'weeks'}...
            </Text>

            <View style={{...habitBoxPositions.one[`tally_${pageNumItems}`], ...pdfStyles.habitContainer}}>
                <Text style={pdfStyles.habitText} >
                    {data.habits[habitOffset - 4]}
                </Text>
            </View>

            { pageNumItems > 1 ? 
                <View style={{...habitBoxPositions.two[`tally_${pageNumItems}`], ...pdfStyles.habitContainer}}>
                    <Text style={pdfStyles.habitText} >
                        {data.habits[habitOffset - 3]}
                    </Text>
                </View>
            : null }

            { pageNumItems > 2 ? 
                <View style={{...habitBoxPositions.three[`tally_${pageNumItems}`], ...pdfStyles.habitContainer}}>
                    <Text style={pdfStyles.habitText} >
                        {data.habits[habitOffset - 2]}
                    </Text>
                </View>
            : null }

            { pageNumItems > 3 ? 
                <View style={{...habitBoxPositions.four[`tally_${pageNumItems}`], ...pdfStyles.habitContainer}}>
                    <Text style={pdfStyles.habitText} >
                        {data.habits[habitOffset - 1]}
                    </Text>
                </View>
            : null }

            <Text style={{bottom:"175px", ...pdfStyles.text }} >
                Then I'll persuade {data.people} {data.people === '1' ? 'other' : 'others'} to do the same! 
            </Text>
        </Page>
    )
}

const renderPages = (data) => {
    const numPages = Math.ceil(data.habits.length/numHabitFields)
    const numWholePages = Math.floor(data.habits.length/numHabitFields)
    return [...Array(numPages)].map((i, j) => {
        const pageNum = j + 1
        let pageNumItems = numHabitFields
        if (pageNum > numWholePages) pageNumItems = data.habits.length % numHabitFields
        return pageContent(pageNum, pageNumItems, data)
    })
}

// Create Document Component
export const MyPoster = ({ data }) => ( 
    <Document>
        { renderPages(data) }
    </Document>
)
