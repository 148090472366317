export const habits = [
    'I’ll remember the 3 Ps - pee, poo and toilet paper - and won’t flush anything else down the toilet!',
    false,
    'I’m going to use a sink strainer to keep food and hair out of the sewers.',
    'I will always scrape my plate to get rid of as much food, grease and oil before rinsing the plate in the sink.',
    'I’m going to investigate reusable alternatives to wet wipes for makeup removal and I will make sure any wet wipes in our home always end up in the bin.',
    'I won’t pour milk down the sink.',
    'I will make sure razors are disposed of in the bin.',
    'I won’t flush period products down the toilet and I will research sustainable alternatives.',
    'I’ll support my family to ensure baby wipes are disposed of correctly (in the bin) and we’ll investigate alternatives to baby wipes like nappy wipe sprays and reusable wipes.',
    'I’ll always put used dental floss in the bin.',
    'I will use a gunk pot to store used fat and oil - once it’s cooled, it will go in the bin and not down the sink!',
    'A bathroom bin will help keep unflushables out of the wastewater network and I’m going to make sure my whole family uses it.',
    'I will always scrape any fats, oils and grease into the bin or use a gunk pot.',
]