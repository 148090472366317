import React, { useState, useEffect } from 'react'
import MovingComponent from 'react-moving-text'
import ActionButton from '../ActionButton/ActionButton'
import styles from './CouldYou.module.scss'

const YouToldUs = (props) => {
    const [currAdvice, setCurrAdvice] = useState(props.filteredAdvice[props.currIndex])
    const [yesSelected, setYesSelected] = useState(false)
    const [noSelected, setNoSelected] = useState(false)

    useEffect(() => {
        setCurrAdvice(props.filteredAdvice[props.currIndex])
    }, [props.filteredAdvice, props.currIndex])

    const yesNoClick = (e) => {
        const answer = e.target.innerHTML
        if (answer === 'Yes') {
            setYesSelected(true)
            setNoSelected(false)
        }
        if (answer === 'No') {
            setNoSelected(true)
            setYesSelected(false)
        }
        if (props.optionSelected) props.optionSelected(answer)
    }

    const YesButton = () => {
        return <ActionButton label="Yes" onClick={yesNoClick} secondary={true} selected={yesSelected} />
    }

    const NoButton = () => {
        return <ActionButton label="No" onClick={yesNoClick} secondary={true} selected={noSelected} />
    }
   
    return(
        <div className={styles.container}>
            <div className={`${styles.advice_container} ${currAdvice?.noBtns ? styles.no_buttons : ''}`}>
                <MovingComponent
                    type='fadeInFromBottom'
                    duration="1000ms"
                    delay="0s"
                    direction="normal"
                    timing="ease"
                    iteration="1"
                    fillMode="none"
                >
                    <p>{ currAdvice.tip }</p>
                    { currAdvice?.url ? <p><a href={currAdvice.url} target="_blank" rel="noreferrer">{currAdvice.url}</a></p> : null }
                </MovingComponent>
                { !currAdvice?.noBtns ? <h2>Could you make this a tiny habit?</h2> : null }
            </div>
            <div className={styles.button_container}>
                { !currAdvice?.noBtns ?
                    <>
                        <NoButton />
                        <YesButton />
                    </>
                : null }
            </div>
        </div>
    )

}

export default YouToldUs
